// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCC5MPqwI4W5XkLQ6P6bXE_Usa1AljU6Y8",
  authDomain: "foxcraft-token.firebaseapp.com",
  projectId: "foxcraft-token",
  storageBucket: "foxcraft-token.appspot.com",
  messagingSenderId: "214656308223",
  appId: "1:214656308223:web:9f2ab402d1eab5d66c8dca"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
