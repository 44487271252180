import React, { useState, useEffect } from 'react';
import { collection, getDocs, setDoc, doc, addDoc, deleteDoc } from 'firebase/firestore';
import { FaChartBar, FaGasPump, FaTrashAlt } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';
import { db } from '../FirebaseConfig';

const PetroleumPriceList = () => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [newSupplierName, setNewSupplierName] = useState('');
    const [newSupplierPrice, setNewSupplierPrice] = useState('');
    const [newLocation, setNewLocation] = useState('');
    const [customLocation, setCustomLocation] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Default to current date
    const [useCurrentDate, setUseCurrentDate] = useState(true); // Default to using the current date
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            const productsSnapshot = await getDocs(collection(db, 'products'));
            const productsData = productsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setProducts(productsData);
        };

        fetchProducts();
    }, []);

    const handleUpdate = async (productId, supplierName, newPrice) => {
        setUploading(true);
        const productDoc = doc(db, 'products', productId);
        const productData = products.find(product => product.id === productId);
        const updatedSuppliers = productData.suppliers.map(supplier =>
            supplier.name === supplierName
                ? {
                      ...supplier,
                      price: Number(newPrice),
                      lastUpdated: useCurrentDate ? new Date().toISOString() : `${selectedDate}T00:00:00Z`,
                  }
                : supplier
        );

        await setDoc(productDoc, { suppliers: updatedSuppliers }, { merge: true });
        alert('Price updated successfully!');
        setUploading(false);
    };

    const handleAddProduct = async () => {
        setUploading(true);
        const productData = {
            name: selectedProduct,
            suppliers: [
                {
                    name: newSupplierName,
                    price: Number(newSupplierPrice),
                    location: newLocation === 'Other' ? customLocation : newLocation,
                    lastUpdated: useCurrentDate ? new Date().toISOString() : `${selectedDate}T00:00:00Z`,
                },
            ],
        };
        const newDocRef = await addDoc(collection(db, 'products'), productData);
        setProducts([...products, { ...productData, id: newDocRef.id }]);
        setSelectedProduct('');
        setNewSupplierName('');
        setNewSupplierPrice('');
        setNewLocation('');
        setCustomLocation('');
        alert('Product added successfully!');
        setUploading(false);
    };

    const handlePriceChange = (productId, supplierName, value) => {
        setProducts(prevProducts =>
            prevProducts.map(product =>
                product.id === productId
                    ? {
                          ...product,
                          suppliers: product.suppliers.map(supplier =>
                              supplier.name === supplierName ? { ...supplier, price: Number(value) } : supplier
                          ),
                      }
                    : product
            )
        );
    };

    const handleDeleteProduct = async (productId) => {
        if (window.confirm('Are you sure you want to delete this product?')) {
            await deleteDoc(doc(db, 'products', productId));
            setProducts(products.filter(product => product.id !== productId));
            alert('Product deleted successfully!');
        }
    };

    return (
        <div className="petroleum-price-list">
            <h2>Update Petroleum Prices <FaChartBar /></h2>

            <div className="content-container">
                <div className="add-product-section">
                    <h3>Add New Product</h3>
                    <div className="date-selector">
                        <label>
                            <input
                                type="checkbox"
                                checked={useCurrentDate}
                                onChange={() => setUseCurrentDate(!useCurrentDate)}
                            />
                            Use Current Date
                        </label>
                        {!useCurrentDate && (
                            <input
                                type="date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            />
                        )}
                    </div>

                    <div className="p">
                        <div className="pj">
                            <label>Product: </label>
                            <select value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
                                <option value="">Select Product</option>
                                <option value="AGO">Diesel (AGO)</option>
                                <option value="DPK">Kerosene (DPK)</option>
                                <option value="PMS">Petrol (PMS)</option>
                                <option value="LPG">Gas (LPG)</option>
                            </select>
                        </div>

                        <div className="pj">
                            <label>Location: </label>
                            <select value={newLocation} onChange={(e) => setNewLocation(e.target.value)}>
                                <option value="">Select Location</option>
                                <option value="Warri">Warri</option>
                                <option value="Port Harcourt">Port Harcourt</option>
                                <option value="Akwa-Ibom">Akwa-Ibom</option>
                                <option value="Lagos">Lagos</option>
                                <option value="Other">Other (Specify)</option>
                            </select>
                        </div>
                    </div>

                    {newLocation === 'Other' && (
                        <div>
                            <input
                                type="text"
                                placeholder="Enter Location"
                                value={customLocation}
                                onChange={(e) => setCustomLocation(e.target.value)}
                            />
                        </div>
                    )}

                    <input
                        className="input-field"
                        type="text"
                        placeholder="Supplier"
                        value={newSupplierName}
                        onChange={(e) => setNewSupplierName(e.target.value)}
                    />
                    <input
                        className="input-field"
                        type="number"
                        placeholder="Price"
                        value={newSupplierPrice}
                        onChange={(e) => setNewSupplierPrice(e.target.value)}
                    />
                    <button onClick={handleAddProduct}>Add Product</button>
                </div>

                {uploading && (
                    <div className="upload-overlay">
                        <Spinner animation="border" variant="primary" />
                        <p>Updating.....</p>
                    </div>
                )}

                <div className="product-container">
                    {products.map((product) => (
                        <div key={product.id} className="product-item">
                            <h3 className="h4">
                                {product.name}
                                <FaTrashAlt
                                    style={{ cursor: 'pointer', marginLeft: '10px' }}
                                    onClick={() => handleDeleteProduct(product.id)}
                                />
                            </h3>
                            {product.suppliers.map((supplier) => (
                                <div key={supplier.name} className="supplier-section">
                                    <h4 className="h4">
                                        ({supplier.name} - {supplier.location}) <FaGasPump />
                                    </h4>
                                    <div className="supplier-div">
                                        <input
                                            className="supplier-div-input"
                                            type="number"
                                            value={supplier.price}
                                            onChange={(e) => handlePriceChange(product.id, supplier.name, e.target.value)}
                                        />
                                        <button
                                            className="supplier-div-button"
                                            onClick={() => handleUpdate(product.id, supplier.name, supplier.price)}
                                        >
                                            Set New Price
                                        </button>
                                    </div>
                                    <p>Last updated: {new Date(supplier.lastUpdated).toLocaleString()}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PetroleumPriceList;
