import "./App.css";
import SideBar from "./components/Sidebar/SideBar";
import { Route, Routes, HashRouter } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoutes";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard"; // Blog component      // Gallery component
import SubscriberComponent from "./components/SubscriberComponent";  // Subscriber section

import BloggingComponent from "./components/BloggingComponents";
import MediaGalleryComponent from "./components/Gallery";
import SubscribersComponent from "./components/SubscriberComponent";
import PetroleumPriceList from "./components/PriceUpdate";


function App() {
  return (
    <HashRouter>
      <SideBar>
      <div className="main">
        <Routes>
          <Route path="/" element={<Login />} />
          
          {/* Protected routes */}
          <Route 
            path="/price-update" 
            element={
              <ProtectedRoute>
                <PetroleumPriceList />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/blog" 
            element={
              <ProtectedRoute>
                <BloggingComponent />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/gallery" 
            element={
              <ProtectedRoute>
                <MediaGalleryComponent />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/subscriber" 
            element={
              <ProtectedRoute>
                <SubscribersComponent />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </div>
      </SideBar>
    </HashRouter>
  );
}

export default App;
