import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../FirebaseConfig';
import Masonry from 'react-masonry-css';
import Modal from 'react-modal';
import { FaTrash } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const MediaGalleryComponent = () => {
    const [mediaItems, setMediaItems] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        const fetchMediaItems = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'mediaItems'));
                const mediaData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setMediaItems(mediaData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching media items:', error);
                setLoading(false);
            }
        };

        fetchMediaItems();
    }, []);

    const handleFileChange = async (e) => {
        setUploading(true);
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const storageRef = ref(storage, `mediaItems/${Date.now()}_${file.name}`);
            try {
                await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(storageRef);
                const mediaData = {
                    url: downloadURL,
                    type: file.type.startsWith('image') ? 'image' : 'video',
                };
                await addDoc(collection(db, 'mediaItems'), mediaData);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        // Refresh the media items list
        const querySnapshot = await getDocs(collection(db, 'mediaItems'));
        const mediaData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setMediaItems(mediaData);
        setUploading(false);
    };

    const handleOpenModal = (media) => {
        setSelectedMedia(media);
        setModalIsOpen(true);
    };

    const handleCloseModal = () => {
        setModalIsOpen(false);
        setSelectedMedia(null);
    };

    const handleDeleteMedia = async (mediaId) => {
        setDeleting(true);
        try {
            await deleteDoc(doc(db, 'mediaItems', mediaId));
            setMediaItems(mediaItems.filter(media => media.id !== mediaId));
            handleCloseModal();
        } catch (error) {
            console.error('Error deleting media item:', error);
        }
        setDeleting(false);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="media-gallery-component">
            <h2>Media Gallery</h2>
            <input type="file" multiple onChange={handleFileChange} className="mb-4" />

            {uploading && (
                <div className="upload-overlay">
                    <Spinner animation="border" variant="primary" />
                    <p>Uploading...</p>
                </div>
            )}

            <Masonry
                breakpointCols={{
                    default: 3,
                    1100: 3,
                    700: 2,
                    500: 2
                }}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
            >
                {mediaItems.map((media) => (
                    <div key={media.id} className="media-item" onClick={() => handleOpenModal(media)}>
                        {media.type === 'image' ? (
                            <img src={media.url} alt="" className="img-fluid" />
                        ) : (
                            <video src={media.url} controls className="img-fluid"></video>
                        )}
                    </div>
                ))}
            </Masonry>

            {/* Modal for viewing and deleting media */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Media Modal"
                style={{
                    content: {
                        maxWidth: '300px',
                        margin: 'auto',
                        padding: '20px',
                        borderRadius: '10px',
                        inset: 'unset', // To prevent modal from stretching across the page
                        overflow: 'auto', // To handle overflow
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Darken the overlay
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }
                }}
            >
                <button onClick={handleCloseModal} className="btn btn-secondary">Close</button>
                {selectedMedia && (
                    <div className="modal-content mt-3">
                        {selectedMedia.type === 'image' ? (
                            <img src={selectedMedia.url} alt="" className="img-fluid" />
                        ) : (
                            <video src={selectedMedia.url} controls className="img-fluid"></video>
                        )}
                        <button 
                            className="btn btn-danger mt-3" 
                            onClick={() => handleDeleteMedia(selectedMedia.id)} 
                            disabled={deleting}
                        >
                            {deleting ? <Spinner animation="border" size="sm" /> : <FaTrash />} Delete
                        </button>
                    </div>
                )}
            </Modal>

        </div>
    );
};

export default MediaGalleryComponent;
