import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../FirebaseConfig';
import 'bootstrap/dist/css/bootstrap.min.css';

const SubscribersComponent = () => {
    const [subscribers, setSubscribers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSubscribers = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'subscribers'));
                const subscribersData = querySnapshot.docs.map(doc => doc.data());
                setSubscribers(subscribersData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching subscribers:', error);
                setLoading(false);
            }
        };

        fetchSubscribers();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="subscribers-component container mt-4">
            <h2>Subscribers</h2>
            <div className="list-group">
                {subscribers.length > 0 ? (
                    subscribers.map((subscriber, index) => (
                        <div key={index} className="list-group-item">
                            {subscriber.email}
                        </div>
                    ))
                ) : (
                    <p>No subscribers yet.</p>
                )}
            </div>
        </div>
    );
};

export default SubscribersComponent;
