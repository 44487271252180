import React, { useState, useEffect, useRef } from 'react';
import { db, storage } from '../FirebaseConfig';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Import Quill's CSS
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import useAuth from '../hooks/useAuth';
import { FaEdit, FaPen, FaTrash } from 'react-icons/fa';

const BloggingComponent = () => {
    const [title, setTitle] = useState('');
    const [images, setImages] = useState([]);
    const [blogPosts, setBlogPosts] = useState([]);
    const [editingPostId, setEditingPostId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false); // Loader state
    const quillRef = useRef(null); // Use useRef to persist the editor reference
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlogs = async () => {
            const querySnapshot = await getDocs(collection(db, 'blogPosts'));
            const blogs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setBlogPosts(blogs);
        };
        fetchBlogs();
    }, []);

    useEffect(() => {
        if (modalOpen) {
            // Initialize Quill only if it's not already initialized
            if (!quillRef.current) {
                quillRef.current = new Quill('#quill-editor', { theme: 'snow' });
            } else {
                // Reinitialize the editor if it was destroyed
                quillRef.current.enable();
            }
        } else if (quillRef.current) {
            // Destroy the Quill instance when the modal is closed
            quillRef.current.disable();
            quillRef.current = null;
        }
    }, [modalOpen]);

    const handleFileChange = (e) => {
        setImages([...e.target.files]);
    };

    const handleSubmit = async () => {
        setLoading(true); // Start loading
        try {
            const uploadedImages = [];
            for (let i = 0; i < images.length; i++) {
                const image = images[i];
                const storageRef = ref(storage, `blogImages/${Date.now()}_${image.name}`);
                await uploadBytes(storageRef, image);
                const downloadURL = await getDownloadURL(storageRef);
                uploadedImages.push(downloadURL);
            }

            const body = quillRef.current ? quillRef.current.root.innerHTML : ''; // Check if quillRef.current exists
            const blogData = {
                title,
                body,
                images: uploadedImages,
                createdAt: new Date(),
            };

            if (editingPostId) {
                // Edit mode
                const postRef = doc(db, 'blogPosts', editingPostId);
                await updateDoc(postRef, blogData);
                alert('Blog post updated successfully!');
            } else {
                // Create mode
                await addDoc(collection(db, 'blogPosts'), blogData);
                alert('Blog post created successfully!');
            }

            setTitle('');
            if (quillRef.current) quillRef.current.root.innerHTML = ''; // Clear the Quill editor content
            setImages([]);
            setEditingPostId(null);
            setModalOpen(false); // Close the modal

            // Refresh blog posts
            const querySnapshot = await getDocs(collection(db, 'blogPosts'));
            const blogs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setBlogPosts(blogs);
        } catch (error) {
            console.error('Error creating blog post:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleEdit = (post) => {
        setTitle(post.title);
        setEditingPostId(post.id);
        setModalOpen(true);
        setTimeout(() => {
            if (quillRef.current) {
                quillRef.current.root.innerHTML = post.body; // Set the Quill editor content to the post body
            }
        }, 100); // Small timeout to ensure the modal and editor are ready
    };

    const handleDelete = async (postId) => {
        try {
            await deleteDoc(doc(db, 'blogPosts', postId));
            alert('Blog post deleted successfully!');
            setBlogPosts(blogPosts.filter(post => post.id !== postId));
        } catch (error) {
            console.error('Error deleting blog post:', error);
        }
    };

    const openModal = () => {
        setModalOpen(true); // Open the modal for creating a new post
    };

    const closeModal = () => {
        setTitle(''); // Clear the title
        if (quillRef.current) quillRef.current.root.innerHTML = ''; // Clear the Quill editor content
        setImages([]); // Clear the images
        setEditingPostId(null); // Reset editing state
        setModalOpen(false); // Close the modal
    };

    return (
        <div className="blogging-component">
            <h2 style={{textAlign:'center'}}>Blog Posts</h2>
            {loading && (
                <div className="upload-overlay">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            )} {/* Loader */}
            <div className="blog-list">
                {blogPosts.map((post) => (
                    <div key={post.id} className="card">
                        <div>
                            <img src={post.images[0]} className="card-img-top" alt={post.title} />
                            <div className="card-body">
                                <h5 className="card-title">{post.title}</h5>
                                <p className="card-text">{new Date(post.createdAt.toDate()).toLocaleDateString()}</p>
                              
                                    <div className="blog-btn">
                                        <button className="btn btn-secondary" onClick={() => handleEdit(post)}><FaEdit/></button>
                                        <button className="btn btn-danger" onClick={() => handleDelete(post.id)}><FaTrash/></button>
                                    </div>
                         
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <button className="btn2" onClick={openModal}><FaPen/></button>

            {/* Bootstrap Modal for Creating/Editing Blog Post */}
            {modalOpen && (
                <div className="modal show d-block" tabIndex="-1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{editingPostId ? 'Edit Post' : 'Create a Blog Post'}</h5>
                                <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    placeholder="Title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <div id="quill-editor" className="editor" /> {/* Use id to initialize Quill */}
                                <input type="file" className="form-control mb-3" multiple onChange={handleFileChange} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleSubmit}>{editingPostId ? 'Update' : 'Submit'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BloggingComponent;
